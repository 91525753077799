/* .react-datepicker-wrapper .react-datepicker__input-container input {
  border-color: red;
}


.react-datepicker-wrapper .react-datepicker__input-container input {
  border: 2px solid red;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  border: 1px solid red;
} */

.date-picker {
  /* border-color: red;
  background-color: red; */
  border-bottom: 1.5px solid #e2e8f0;
  padding: 2px;
  padding-left: 5px;
  width: 100%;

  /* border-radius: 3px; */
}

.react-datepicker__input-container input:focus {
  border-bottom: 2px solid #8952e0;
  outline: none;
}
